import service from '@/utils/request';

// @Tags RmParamType
// @Summary 创建RmParamType
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmParamType true "创建RmParamType"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmParamType/createRmParamType [post]
export var createRmParamType = function createRmParamType(data) {
  return service({
    url: "/rmParamType/createRmParamType",
    method: 'post',
    data: data
  });
};

// @Tags RmParamType
// @Summary 删除RmParamType
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmParamType true "删除RmParamType"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmParamType/deleteRmParamType [delete]
export var deleteRmParamType = function deleteRmParamType(data) {
  return service({
    url: "/rmParamType/deleteRmParamType",
    method: 'delete',
    data: data
  });
};

// @Tags RmParamType
// @Summary 删除RmParamType
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除RmParamType"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmParamType/deleteRmParamType [delete]
export var deleteRmParamTypeByIds = function deleteRmParamTypeByIds(data) {
  return service({
    url: "/rmParamType/deleteRmParamTypeByIds",
    method: 'delete',
    data: data
  });
};

// @Tags RmParamType
// @Summary 更新RmParamType
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmParamType true "更新RmParamType"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /rmParamType/updateRmParamType [put]
export var updateRmParamType = function updateRmParamType(data) {
  return service({
    url: "/rmParamType/updateRmParamType",
    method: 'put',
    data: data
  });
};

// @Tags RmParamType
// @Summary 用id查询RmParamType
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmParamType true "用id查询RmParamType"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /rmParamType/findRmParamType [get]
export var findRmParamType = function findRmParamType(params) {
  return service({
    url: "/rmParamType/findRmParamType",
    method: 'get',
    params: params
  });
};

// @Tags RmParamType
// @Summary 分页获取RmParamType列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取RmParamType列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmParamType/getRmParamTypeList [get]
export var getRmParamTypeList = function getRmParamTypeList(params) {
  return service({
    url: "/rmParamType/getRmParamTypeList",
    method: 'get',
    params: params
  });
};
export var getAllRmParamTypeList = function getAllRmParamTypeList(params) {
  return service({
    url: "/rmParamType/getAllRmParamTypeList",
    method: 'get',
    params: params
  });
};