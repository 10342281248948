import service from '@/utils/request';

// @Tags RmParam
// @Summary 创建RmParam
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmParam true "创建RmParam"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmParam/createRmParam [post]
export var createRmParam = function createRmParam(data) {
  return service({
    url: "/rmParam/createRmParam",
    method: 'post',
    data: data
  });
};

// @Tags RmParam
// @Summary 删除RmParam
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmParam true "删除RmParam"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmParam/deleteRmParam [delete]
export var deleteRmParam = function deleteRmParam(data) {
  return service({
    url: "/rmParam/deleteRmParam",
    method: 'delete',
    data: data
  });
};

// @Tags RmParam
// @Summary 删除RmParam
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除RmParam"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmParam/deleteRmParam [delete]
export var deleteRmParamByIds = function deleteRmParamByIds(data) {
  return service({
    url: "/rmParam/deleteRmParamByIds",
    method: 'delete',
    data: data
  });
};

// @Tags RmParam
// @Summary 更新RmParam
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmParam true "更新RmParam"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /rmParam/updateRmParam [put]
export var updateRmParam = function updateRmParam(data) {
  return service({
    url: "/rmParam/updateRmParam",
    method: 'put',
    data: data
  });
};

// @Tags RmParam
// @Summary 用id查询RmParam
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmParam true "用id查询RmParam"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /rmParam/findRmParam [get]
export var findRmParam = function findRmParam(params) {
  return service({
    url: "/rmParam/findRmParam",
    method: 'get',
    params: params
  });
};

// @Tags RmParam
// @Summary 分页获取RmParam列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取RmParam列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmParam/getRmParamList [get]
export var getRmParamList = function getRmParamList(params) {
  return service({
    url: "/rmParam/getRmParamList",
    method: 'get',
    params: params
  });
};
export var getAllRmParamlList = function getAllRmParamlList(params) {
  return service({
    url: "/rmParam/getAllRmParamList",
    method: 'get',
    params: params
  });
};